





import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class TextContent extends Vue {
  @Prop({ default: "Lorem ipsum" }) content?: string;
  @Prop({ default: "white" }) color?: string;
}
